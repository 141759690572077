<template>
    <div style="background-color: #ffffff;">
        <div>
            <div class="pageCenter titleBar " :class="[statu==4?'enterHeadWidth':'pageWidth']">
                <!-- <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}"> -->
                <div class="titleBar_left">
                    <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                    <div class="titleBar_left_txt">
                        企业管理中心
                    </div>
                </div>
                <div class="titleBar_right" @click="$oucy.replace('/')">
                    返回首页
                </div>
            </div>
        </div>
        <div class="pageCenter breadcrumbBar" :style="{width: pageWidth+'px'}">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">企业管理中心</a></el-breadcrumb-item>
                <!-- <el-breadcrumb-item>个人资料</el-breadcrumb-item> -->
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1')">帮助手册</el-button>
        </div>
        <div class="pageCenter text-left m-b-20" :style="{width: pageWidth+'px'}">
            <div class="f24 bold text-center">查看物流</div>
            <lineTitle text="订单信息"></lineTitle>
            <div class="m-b-40">
                <order v-for="(v,i) of 1" class="m-t-20" :key="i"></order>
            </div>
            <lineTitle text="物流跟踪"></lineTitle>
            <div class="block m-t-20">
              <el-timeline>
                <el-timeline-item
                placement="top"
                  v-for="(activity, index) in activities"
                  :key="index"
                  :icon="activity.icon"
                  :type="activity.type"
                  :color="activity.color"
                  :size="activity.size"
                  :timestamp="activity.timestamp">
                  <el-card>
                     <h4>更新 Github 模板</h4>
                   </el-card>
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="text-center m-t-20 p-b-50">
                <el-button type="primary">返回</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { userAuth } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "refund",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            form:{},
            statu: 0,
            input3: null,
            select: null,
            queryFurnitureLibraryObj: {},
            input: null,
            activities: [{
                     content: '支持使用图标',
                     timestamp: '2018-04-12 20:46',
                     size: 'large',
                     type: 'primary',
                     color: '#2090FF',
                     icon: 'el-icon-check'
                   }, {
                     content: '支持自定义颜色',
                     timestamp: '2018-04-03 20:46',
                     size: 'large',
                     type: 'primary',
                     // color: '#2090FF',
                   }, {
                     content: '支持自定义尺寸',
                     timestamp: '2018-04-03 20:46',
                     size: 'large'
                   }, {
                     content: '默认样式的节点',
                     timestamp: '2018-04-03 20:46'
                   }]
        }
    },
    computed: {

    },
    mounted() {},
    methods: {
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            // me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            // me.queryFurnitureLibrary();
        },
        onSubmit(){},
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}
</style>